.numToast {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    background-image: none;

    margin-bottom: 1rem;

    position: relative;
    overflow: hidden;

    max-width: 30rem;

    &:last-child {
        margin-bottom: 0;
    } // &:last-child

    .toast-close-button {
        position: absolute;
        z-index: 4;

        right: 0.5rem;
        top: 0.5rem;
    } // .toast-close-button

    .toast-title {
        color: var(--num-light);
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.25rem;
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 3;
    } // .toast-title

    .toast-message {
        color: rgba(255, 255, 255, 0.75);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.25rem;
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 3;
        max-height: 10rem;
        overflow: hidden;
    } // .toast-message

    .toast-progress {
        height: 100%;
        background-color: rgba(0, 0, 0, 0.25);
        z-index: 2;
    } // .toast-progress

    &.toast-success {
        background: var(--num-green);
    } // &.toast-error

    &.toast-error {
        background: var(--num-red);
    } // &.toast-error

    &.toast-warning {
        background: var(--num-yellow);
    } // &.toast-warning

    &.toast-info {
        background: rgba(0, 0, 0, 0.25);
    } // &.toast-info
} // .numToast