.btnLoading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 3rem;
    height: 1rem;

    margin-left: 0.5rem;
} // .btnLoading

.btnLoading > div {
    width: 0.75rem;
    height: 0.75rem;

    background: #fff;

    border-radius: 0.75rem;
    display: sblock;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.btnLoading .b1 {
    animation-delay: -0.32s;
}

.btnLoading .b2 {
    animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        transform: scale(0);
    } 40% {
        transform: scale(1.0);
    }
}