*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}


:root {
    font-size: 1rem;

    @media (prefers-reduced-motion: no-preference) {
      scroll-behavior: smooth;
    }
}

body {
    font-family: 'Graphik Garuna Web', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;

    color: #000;
    background-color: #fff;

    margin: 0;

    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}

p {
    margin-top: 0;
}

a {
    color: #000;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

a:not([href]):not([class]) {
    &,
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

img,
svg {
    vertical-align: middle;
}

label {
    display: inline-block;
}

button {
    border-radius: 0;
}

button:focus {
    outline: dotted 1px;
    outline: -webkit-focus-ring-color auto 5px;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
select {
    text-transform: none;
}

[role="button"] {
    cursor: pointer;
}

select {
    word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
    display: none;
}


button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
    &:not(:disabled) {
        cursor: pointer;
    }
}

::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

textarea {
    resize: vertical;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
    padding: 0;
}

::-webkit-inner-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
}

::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
    padding: 0;
}

::file-selector-button {
    font: inherit;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
    border: 0;
}

[hidden] {
    display: none !important;
}

numerology-root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
} // numerology-root

main {
    opacity: 1;
    animation: animated 0.5s linear forwards;

    @keyframes animated {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    } // @keyframes animated
} // main