@font-face {
    font-family: 'Graphik Garuna Web';
    src: url('/assets/fonts/Graphik-Regular-Web.woff2') format('woff2'), url('/assets/fonts/Graphik-Regular-Web.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik Garuna Web';
    src: url('/assets/fonts/Graphik-Regular-Italic-Web.woff2') format('woff2'), url('/assets/fonts/Graphik-Regular-Italic-Web.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik Garuna Web';
    src: url('/assets/fonts/Graphik-Medium-Web.woff2') format('woff2'), url('/assets/fonts/Graphik-Medium-Web.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik Garuna Web';
    src: url('/assets/fonts/Graphik-Medium-Italic-Web.woff2') format('woff2'), url('/assets/fonts/Graphik-Medium-Italic-Web.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik Garuna Web';
    src: url('/assets/fonts/Graphik-Semibold-Web.woff2') format('woff2'), url('/assets/fonts/Graphik-Semibold-Web.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik Garuna Web';
    src: url('/assets/fonts/Graphik-Bold-Web.woff2') format('woff2'), url('/assets/fonts/Graphik-Bold-Web.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}