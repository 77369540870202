$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    xga: 1600px,
    // fhd: 1920px,
    // rt: 2560px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
    xga: 1530px,
    // fhd: 1830px,
    // rt: 2470px
);