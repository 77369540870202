@import './styles/vars';
@import './styles/fonts';
@import './styles/reset';

@import './styles/grid-vars';
@import '~bootstrap/scss/bootstrap-grid';
@import './styles/components';

@import '~ngx-toastr/toastr';

/* body overflow is hidden to hide main scrollbar when modal window is open */
body.modal-open { overflow: hidden }