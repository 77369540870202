.header {
    display: flex;
    align-items: center;

    position: relative;

    z-index: 3;
    top: 0;
    left: 0;

    width: 100%;

    padding-top: var(--num-container-padding);
    padding-bottom: var(--num-container-padding);

    &-logo {
        display: block;

        height: 3rem;
        width: auto;

        user-select: none;
        pointer-events: none;

        fill: var(--num-light);
    } // &-logo

    &-site {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        text-decoration: none;

        padding: 0.5rem 0.75rem;
        border-radius: 0.5rem;
        transition: var(--num-transition-primary);

        &:hover {
            background: rgba(var(--num-light-rgb), 0.25);
        }

        &__subhead {
            display: block;

            color: rgba(var(--num-light-rgb), 0.5);
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 0.75rem;

            margin: 0;
            padding: 0;

            margin-bottom: 0.25rem;
        } // &__subhead

        &__img {
            display: block;

            height: 2rem;
            width: auto;

            fill: var(--num-light);

            user-select: none;
        } // &__img
    } // &-site

    &-btn {
        $root: &;

        display: flex;
        align-items: center;

        background: var(--num-white);
        border-radius: 0.5rem;
        border: 1px solid rgba(var(--num-dark-rgb), 0.125);

        text-decoration: none;

        margin-left: 2rem;

        padding: 0.75rem 1rem;

        box-shadow: 0 0 0 0 rgba(var(--num-black-rgb), 0.25);
        transition: var(--num-transition-primary);

        &:hover {
            cursor: pointer;
            transform: translateY(-3px);
            background: var(--num-dark);
            border-color: var(--num-dark);
            box-shadow: 0 0.5rem 1rem -0.25rem rgba(var(--num-dark-rgb), 0.5);
        }

        &__text {
            display: block;

            color: var(--num-dark);
            font-size: 1rem;
            font-weight: 500;
            line-height: 1rem;

            transition: var(--num-transition-primary);

            #{$root}:hover & {
                color: var(--num-light);
            } // #{$root}:hover &
        } // &__text

        &__icon {
            display: block;

            color: var(--num-primary);
            font-size: 1.25rem;

            margin-left: 0.5rem;
        } // &__icon
    } // &-btn
} // .header