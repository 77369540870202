:root {
    --num-white: #ffffff;
    --num-white-rgb: 255, 255, 255;
    --num-black: #000000;
    --num-black-rgb: 0, 0, 0;
    --num-primary: #4ecdc4;
    --num-primary-rgb: 78, 205, 196;
    --num-dark: #1a535c;
    --num-dark-rgb: 26, 83, 92;
    --num-light: #f7fff7;
    --num-light-rgb: 247, 255, 247;
    --num-red: #ff6b6b;
    --num-red-rgb: 255, 107, 107;
    --num-yellow: #ffe66d;
    --num-yellow-rgb: 255, 230, 109;
    --num-green: #6ca51b;
    --num-green-rgb: 108, 165, 27;
    --num-container-padding: 2rem;
    --num-transition-primary: all 0.15s linear;
}